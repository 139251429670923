import type { Alert, Article, InvoicePage, UserAPI } from '~/shared/types';
import { uuidv4 } from '@firebase/util';

export async function getArticles(token: string): Promise<Article[]> {
  const response = await fetch('/api/infos/getArticles', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
  });
  return response.json();
}

export async function getAlert(token: string): Promise<Alert> {
  const response = await fetch('/api/infos/getAlert', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
  });
  return response.json();
}

export async function getUserDetails(userId: string, token: string): Promise<UserAPI | null> {
  const response = await fetch(`/api/documents/getUserDetails?userId=${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
  });
  if (response.status === 204) {
    return null;
  }
  if (!response.ok) {
    throw new Error("Erreur lors de la récupération du détail de l'utilisateur");
  }
  return await response.json();
}

export async function getInvoices(token: string, userId: string): Promise<InvoicePage> {
  const response = await fetch('/api/documents/getInvoices', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
    body: JSON.stringify({
      user_id: userId,
    }),
  });
  return await response.json();
}
